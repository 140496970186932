export default [
  {
    path: '/config/design',
    name: 'config-design',
    component: () => import('@/views/config-design/index.vue'),
    meta: {
      pageTitle: '开发配置',
      resource: 'system-config',
      action: 'index',
      breadcrumb: [
        {
          text: '系统配置',
          active: true,
        },
        {
          text: '开发配置',
          active: true,
        },
      ],
    },
  },
]

export default [
  {
    path: '/account/list',
    name: 'account-list',
    component: () => import('@/views/system/account-list/UsersList.vue'),
    meta: {
      pageTitle: '账户管理',
      resource: 'system-role-accout',
      action: 'index',
      breadcrumb: [
        {
          text: '账号权限',
          active: true,
        },
      ],
    },
  },
  {
    path: '/role/list',
    name: 'role-list',
    component: () => import('@/views/system/roleManage/roleList.vue'),
    meta: {
      pageTitle: '角色管理',
      resource: 'system-role-manage',
      action: 'index',
      breadcrumb: [ { text: '账号权限', active: true, } ],
    },
  },
  {
    path: '/rules/competency',
    name: 'rules-competency',
    component: () => import('@/views/system/rules/index.vue'),
    meta: {
      pageTitle: '权限规则',
      resource: 'system-role-list',
      action: 'index',
      breadcrumb: [ { text: '账号权限', active: true } ],
    },
  },
  {
    path: '/api/list',
    name: 'api-list',
    component: () => import('@/views/system/api/index.vue'),
    meta: {
      pageTitle: 'API接口',
      resource: 'system-api-list',
      action: 'index',
      breadcrumb: [ { text: '账号权限', active: true } ],
    },
  },
]

export default {
  // Endpoints
  loginEndpoint: '/adminuser/login',
  registerEndpoint: '/adminuser/register',
  refreshEndpoint: '/adminuser/refreshToken',
  logoutEndpoint: '/adminuser/logout',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageTokenKeyTime: 'accessTokenExpiresAt',
  storageRefreshTokenKeyName: 'refreshToken',
  storageRefreshTokenKeyTime: 'refreshTokenExpiresAt',
}

export default [
    {
        path: "/website/news/cate",
        name: "news-cate",
        component: () => import("@/views/website/newsCate/index.vue"),
        meta: {
            pageTitle: "新闻分类",
            resource: 'website-news-cate',
            action: 'index',
            breadcrumb: [
                {
                    text: "网站管理",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/website/news/list",
        name: "news-list",
        component: () => import("@/views/website/news/index.vue"),
        meta: {
            pageTitle: "新闻列表",
            resource: 'website-news-list',
            action: 'index',
            breadcrumb: [
                {
                    text: "网站管理",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/website/zixun/cate",
        name: "zixun-cate",
        component: () => import("@/views/website/zixunCate/index.vue"),
        meta: {
            pageTitle: "业务分类",
            resource: 'website-business-cate',
            action: 'index',
            breadcrumb: [
                {
                    text: "网站管理",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/website/zixun/list",
        name: "zixun-list",
        component: () => import("@/views/website/zixun/index.vue"),
        meta: {
            pageTitle: "业务列表",
            resource: 'website-business-list',
            action: 'index',
            breadcrumb: [
                {
                    text: "网站管理",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/website/feedback/list",
        name: "feedback-list",
        component: () => import("@/views/website/feedback/index.vue"),
        meta: {
            pageTitle: "客户留言",
            resource: 'website-feedback',
            action: 'index',
            breadcrumb: [
                {
                    text: "网站管理",
                    active: true,
                },
            ],
        },
    },
    {
        path: "/website/pages/list",
        name: "website-web",
        component: () => import("@/views/website/pages/index.vue"),
        meta: {
            pageTitle: "网站页面",
            resource: 'website-web',
            action: 'index',
            breadcrumb: [
                {
                    text: "网站管理",
                    active: true,
                },
            ],
        },
    },
]

export default [
  {
    path: '/system/files',
    name: 'system-files',
    component: () => import('@/views/system/files/index.vue'),
    meta: {
      resource: 'system-file',
      action: 'index',
      contentRenderer: 'sidebar-left',
      contentClass: 'file-application',
    },
  },
]

import Vue from 'vue'
import { BootstrapVue, ToastPlugin, ModalPlugin } from 'bootstrap-vue'
// import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import i18n from '@/libs/i18n'
import router from './router'
import store from './store'
import App from './App.vue'

// Global Components
import './global-components'

// 3rd party plugins
import '@axios'
import '@/libs/acl'
import '@/libs/portal-vue'
import '@/libs/clipboard'
import '@/libs/toastification'
import '@/libs/sweet-alerts'
import '@/libs/vue-select'
import '@/libs/tour'
// vxe - table
import 'xe-utils'
import VXETable from 'vxe-table'
import 'vxe-table/lib/style.css'
// import VXETablePluginIView from 'vxe-table-plugin-iview'
// import 'vxe-table-plugin-iview/dist/style.css'
// iview UI
import ViewUI from 'view-design'
import 'view-design/dist/styles/iview.css'
// viewer
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'

// Axios Mock Adapter
import '@/@fake-db/db'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVue)

Vue.use(VXETable)

Vue.use(Viewer)

Vue.use(ViewUI)

// Vue.use(VXETablePluginIView)
// Composition API
Vue.use(VueCompositionAPI)

// Feather font icon - For form-wizard
// * Shall remove it if not using font-icons of feather-icons - For form-wizard
require('@core/assets/fonts/feather/iconfont.css') // For form-wizard

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

export default [
  {
    path: '/system/depart',
    name: 'system-depart',
    component: () => import('@/views/system/depart/index.vue'),
    meta: {
      pageTitle: '组织架构',
      resource: 'system-organization',
      action: 'index',
      breadcrumb: [
        {
          text: '系统管理',
          active: true,
        },
      ],
    },
  },
]

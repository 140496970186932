import jwtDefaultConfig from './jwtDefaultConfig'
import router from '@/router'
// Notification
import Vue from 'vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default class JwtService {
  // Will be used by this service for making API calls
  axiosIns = null

  noAuth = false

  // jwtConfig <= Will be used by this service
  jwtConfig = { ...jwtDefaultConfig }

  // For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // For Refreshing Token
  subscribers = []

  constructor(axiosIns, jwtOverrideConfig) {
    this.axiosIns = axiosIns

    this.jwtConfig = { ...this.jwtConfig, ...jwtOverrideConfig }

    // Request Interceptor
    this.axiosIns.interceptors.request.use(
      config => {
        // Get token from localStorage
        const accessToken = this.getToken()

        // If token is present add it to request's Authorization Header
        if (accessToken) {
          // eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
        }

        return config
      },
      error => Promise.reject(error),
    )

    // Add request/response interceptor
    this.axiosIns.interceptors.response.use(
      response => {
        const { config, data } = response
        const originalRequest = config

        // 兼容 原来框架 mock 数据 后期框架修改完成，可以直接删除
        if(data && !data.code) { return Promise.resolve(response) }

        // 判断是否是成功的回调
        if (data && data.success) { return Promise.resolve(data) }
        // 如果回调失败，判断失败的原因
        else {
          // 请求错误
          if (data.code == 201) { return Promise.reject(data) }
          // 权限不足
          if (data.code == 102) { return Promise.reject(data) }
          // 参数不足
          if (data.code == 103) { return Promise.reject(data) }
          // token 格式错误
          if (data.code == 40005) { this.logout() }
          // token 过期
          if (data.code == 40006) {
            if (!this.isAlreadyFetchingAccessToken) {
              this.isAlreadyFetchingAccessToken = true
              this.refreshToken()
                .then(r => {
                  this.isAlreadyFetchingAccessToken = false
                  // Update accessToken in localStorage
                  this.setToken(r.data.accessToken)
                  this.setTokenTime(r.data.accessTokenExpiresAt)
                  this.setRefreshToken(r.data.refreshToken)
                  this.setRefreshTokenTime(r.data.refreshTokenExpiresAt)
                  this.onAccessTokenFetched(r.data.accessToken)
                })
                .catch(err => {
                  Vue.$toast({
                    component: ToastificationContent,
                    props: { title: '登录超时', text: '您的登录时间也过期,请重新登录!', icon: 'CoffeeIcon', variant: 'danger' },
                  })
                  this.logout()
                })
            }
            const retryOriginalRequest = new Promise(resolve => {
              this.addSubscriber(accessToken => {
                originalRequest.headers.Authorization = `${this.jwtConfig.tokenType} ${accessToken}`
                resolve(this.axiosIns(originalRequest))
              })
            })
            return retryOriginalRequest
          }
          // refreshToken 格式错误
          if (data.code == 40007) { this.logout() }
          // refreshToken 过期
          if (data.code == 40008) { return Promise.reject(data) }

          return Promise.reject(data)
        }
      },
      error => {
        if (error && error.response) {
          switch (error.response.status) {
            case 400:
              error.response.data.msg = '请求错误'
              break
            case 401:
              error.response.data.msg = '未授权，请登录'
              break
            case 403:
              error.response.data.msg = '拒绝访问'
              break
            case 404:
              error.response.data.msg = `请求地址出错: ${error.response.config.url}`
              break
            case 408:
              error.response.data.msg = '请求超时'
              break
            case 500:
              error.response.data.msg = '服务器内部错误'
              break
            case 501:
              error.response.data.msg = '服务未实现'
              break
            case 502:
              error.response.data.msg = '网关错误'
              break
            case 503:
              error.response.data.msg = '服务不可用'
              break
            case 504:
              error.response.data.msg = '网关超时'
              break
            case 505:
              error.response.data.msg = 'HTTP版本不受支持'
              break
            default:
              break
          }
        }

        Vue.$toast({
          component: ToastificationContent,
          props: { title: '网络错误', text: error.response.data.msg, icon: 'CoffeeIcon', variant: 'danger' },
        })
        return Promise.reject(error.response.data)
      },
    )
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyName)
  }

  getTokenTime() {
    return localStorage.getItem(this.jwtConfig.storageTokenKeyTime)
  }

  getRefreshToken() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyName)
  }

  getRefreshTokenTime() {
    return localStorage.getItem(this.jwtConfig.storageRefreshTokenKeyTime)
  }

  setToken(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyName, value)
  }

  setTokenTime(value) {
    localStorage.setItem(this.jwtConfig.storageTokenKeyTime, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyName, value)
  }

  setRefreshTokenTime(value) {
    localStorage.setItem(this.jwtConfig.storageRefreshTokenKeyTime, value)
  }

  login(...args) {
    return this.axiosIns.post(this.jwtConfig.loginEndpoint, ...args)
  }

  register(...args) {
    return this.axiosIns.post(this.jwtConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return this.axiosIns.post(this.jwtConfig.refreshEndpoint, {
      refreshToken: this.getRefreshToken(),
    })
  }

  logout() {
    localStorage.removeItem(this.jwtConfig.storageTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageTokenKeyTime)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyName)
    localStorage.removeItem(this.jwtConfig.storageRefreshTokenKeyTime)
    // Remove userData from localStorage
    localStorage.removeItem('userData')
    router.replace({ name: 'auth-login' })
  }
}

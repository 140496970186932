/*
 * @Author: YXM
 * @Date: 2022-07-15 11:59:00
 * @LastEditTime: 2022-08-05 11:13:06
 * @FilePath: /zhaoshang_admin_pc_fe_0720/themeConfig.js
 * @Description: 
 */
// Theme Colors
// Initially this will be blank. Later on when app is initialized we will assign bootstrap colors to this from CSS variables.
export const $themeColors = {}

// App Breakpoints
// Initially this will be blank. Later on when app is initialized we will assign bootstrap breakpoints to this object from CSS variables.
export const $themeBreakpoints = {}

// APP CONFIG
export const $themeConfig = {
  app: {
    appName: '智慧招商', // Will update name in navigation menu (Branding)
    // eslint-disable-next-line global-require
    // appLogoImage: require('@/assets/images/logo/logo.svg'), // Will update logo in navigation menu (Branding)
    appLogoImage: require('@/assets/images/logo/logo.png'), // Will update logo in navigation menu (Branding)
  },
  layout: {
    isRTL: false,
    skin: 'light', // light, dark, bordered, semi-dark
    routerTransition: 'zoom-fade', // zoom-fade, slide-fade, fade-bottom, fade, zoom-out, none
    type: 'vertical', // vertical, horizontal
    contentWidth: 'full', // full, boxed
    menu: {
      hidden: false,
      isCollapsed: false,
    },
    navbar: {
      // ? For horizontal menu, navbar type will work for navMenu type
      type: 'floating', // static , sticky , floating, hidden
      backgroundColor: '', // BS color options [primary, success, etc]
    },
    footer: {
      type: 'static', // static, sticky, hidden
    },
    customizer: true,
    enableScrollToTop: true,
  },
}

export const $urlConfig = {
  // url 请求地址
  // apiBaseURL: 'https://beilun.myyll.com/adminapi',
  // baseUrl: 'https://beilun.myyll.com/',

  // apiBaseURL: 'http://192.168.0.254:9190/adminapi',
  // baseUrl: 'http://192.168.0.254:9190/',

  apiBaseURL: 'https://zsapi.ht-itc.com/adminapi' || 'https://business.bjstarfish.com/adminapi',
  baseUrl: 'https://zsapi.ht-itc.com/' || 'https://business.bjstarfish.com/',
}

export const $fileUploadConfig = {
  // wss 地址
  wsSocketUrl: '',
  // 单文件导入接口地址
  fileUrlSingle: $urlConfig.apiBaseURL + '/file/uploadFile',
  // 多文件导入接口地址
  fileUrlMutiple: $urlConfig.apiBaseURL + '/file/upload',
}

export default [
  // {
  //   path: '/system/depart',
  //   name: 'system-depart',
  //   component: () => import('@/views/system/depart/index.vue'),
  //   meta: {
  //     pageTitle: '组织架构',
  //     breadcrumb: [
  //       {
  //         text: '系统管理',
  //         active: true,
  //       },
  //     ],
  //   },
  // },
  {
    path: "/combined/data",
    name: "combined-data",
    component: () => import("@/views/system/combined/index.vue"),
    meta: {
      pageTitle: "组合数据",
      resource: 'system-combin',
      action: 'index',
      breadcrumb: [
        {
          text: "系统管理",
          active: true,
        },
      ],
    }, 
  },
]

export default [
  {
    path: '/project/list',
    name: 'project-list',
    component: () => import('@/views/manage/project/index.vue'),
    meta: {
      pageTitle: '项目列表',
      resource: 'extension-project-list',
      action: 'list',
      breadcrumb: [
        {
          text: '推广服务',
          active: true,
        },
        {
          text: '项目管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/project/info/:id',
    name: 'project-info',
    component: () => import('@/views/manage/projectInfo/index.vue'),
    meta: {
      pageTitle: '项目详情',
      resource: 'extension-project-list',
      action: 'info',
      breadcrumb: [
        {
          text: '推广服务',
          active: true,
        },
        {
          text: '项目管理',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      if (to.params.id >= 0) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/product/list',
    name: 'product-list',
    component: () => import('@/views/manage/product/index.vue'),
    meta: {
      pageTitle: '产品列表',
      resource: 'extension-project-product',
      action: 'list',
      breadcrumb: [
        {
          text: '推广服务',
          active: true,
        },
        {
          text: '项目产品',
          active: true,
        },
      ],
    },
  },
  {
    path: '/callcard/list',
    name: 'callcard-list',
    component: () => import('@/views/manage/callcard/index.vue'),
    meta: {
      pageTitle: '名片列表',
      resource: 'extension-card',
      action: 'list',
      breadcrumb: [
        {
          text: '推广服务',
          active: true,
        },
        {
          text: '名片管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/callcard/info/:id/:user',
    name: 'callcard-info',
    component: () => import('@/views/manage/cardinfo/index.vue'),
    meta: {
      pageTitle: '名片详情',
      resource: 'extension-card',
      action: 'info',
      breadcrumb: [
        {
          text: '推广服务',
          active: true,
        },
        {
          text: '名片管理',
          active: true,
        },
      ],
    },
    beforeEnter(to, _, next) {
      if (to.params.id >= 0) next()
      else next({ name: 'error-404' })
    },
  },
  {
    path: '/staff/list',
    name: 'staff-list',
    component: () => import('@/views/manage/staff/index.vue'),
    meta: {
      pageTitle: '员工列表',
      resource: 'extension-staff',
      action: 'list',
      breadcrumb: [
        {
          text: '推广服务',
          active: true,
        },
        {
          text: '员工管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/policy/list',
    name: 'policy-list',
    component: () => import('@/views/manage/policy/index.vue'),
    meta: {
      pageTitle: '政策列表',
      resource: 'extension-project-policy',
      action: 'list',
      breadcrumb: [
        {
          text: '推广服务',
          active: true,
        },
        {
          text: '项目管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/image/list',
    name: 'image-list',
    component: () => import('@/views/manage/image/index.vue'),
    meta: {
      pageTitle: '图册列表',
      resource: 'extension-project-picture',
      action: 'list',
      breadcrumb: [
        {
          text: '推广服务',
          active: true,
        },
        {
          text: '项目管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/video/list',
    name: 'video-list',
    component: () => import('@/views/manage/video/index.vue'),
    meta: {
      pageTitle: '视频列表',
      resource: 'extension-project-video',
      action: 'list',
      breadcrumb: [
        {
          text: '推广服务',
          active: true,
        },
        {
          text: '项目管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/talks/list',
    name: 'talks-list',
    component: () => import('@/views/manage/talks/index.vue'),
    meta: {
      pageTitle: '话术列表',
      resource: 'extension-library-list',
      action: 'list',
      breadcrumb: [
        {
          text: '推广服务',
          active: true,
        },
        {
          text: '项目管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/talks/cate',
    name: 'talks-cate',
    component: () => import('@/views/manage/talkscate/index.vue'),
    meta: {
      pageTitle: '话术分类',
      resource: 'extension-library-cate',
      action: 'list',
      breadcrumb: [
        {
          text: '推广服务',
          active: true,
        },
        {
          text: '项目管理',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report/resources/share/statistics',
    name: 'resources-share-statistics',
    component: () => import('@/views/manage/report/resources-share-statistics/index.vue'),
    meta: {
      pageTitle: '要素推广汇总',
      resource: 'extension-report-element',
      action: 'report',
      breadcrumb: [
        {
          text: '管理报表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report/staff/share/statistics',
    name: 'staff-share-statistics',
    component: () => import('@/views/manage/report/staff-share-statistics/index.vue'),
    meta: {
      pageTitle: '员工推广统计',
      resource: 'extension-report-staff',
      action: 'report',
      breadcrumb: [
        {
          text: '管理报表',
          active: true,
        },
      ],
    },
  },
  {
    path: '/report/customer/clue/statistics',
    name: 'customer-clue-statistics',
    component: () => import('@/views/manage/report/customer-clue-statistics/index.vue'),
    meta: {
      pageTitle: '客户线索统计',
      resource: 'extension-report-customer',
      action: 'report',
      breadcrumb: [
        {
          text: '管理报表',
          active: true,
        },
      ],
    },
  },
  {
    path: "/website/staff/image/cate",
    name: "staff-image-cate",
    component: () => import("@/views/manage/staffImageCate/index.vue"),
    meta: {
        pageTitle: "员工海报分类",
        resource: 'extension-resources-posterCate',
        action: 'list',
        breadcrumb: [
            {
                text: "推广服务",
                active: true,
            },
        ],
    },
  },
  {
    path: "/website/staff/video/cate",
    name: "staff-video-cate",
    component: () => import("@/views/manage/staffVideoCate/index.vue"),
    meta: {
        pageTitle: "员工视频分类",
        resource: 'extension-resources-videoCate',
        action: 'list',
        breadcrumb: [
            {
                text: "推广服务",
                active: true,
            },
        ],
    },
  },
  {
    path: "/website/staff/image/list",
    name: "staff-image-list",
    component: () => import("@/views/manage/staffImageList/index.vue"),
    meta: {
        pageTitle: "员工海报管理",
        resource: 'extension-resources-poster',
        action: 'list',
        breadcrumb: [
            {
                text: "推广服务",
                active: true,
            },
        ],
    },
  },
  {
    path: "/website/staff/video/list",
    name: "staff-video-list",
    component: () => import("@/views/manage/staffVideoList/index.vue"),
    meta: {
        pageTitle: "员工视频管理",
        resource: 'extension-resources-video',
        action: 'list',
        breadcrumb: [
            {
                text: "推广服务",
                active: true,
            },
        ],
    },
  },
]